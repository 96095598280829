// Loading
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING '
// JWTTOKEN

// export const TOKEN_GENRATE = 'TOKEN_GENRATE'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN'
// SIGNUP END
export const REGISTER_ADD_USERS_SUCCESS = 'REGISTER_ADD_USERS_SUCCESS';
export const REGISTER_ADD_FAILURE = 'REGISTER_ADD_FAILURE';
// SIGNUP END
// LOGIN
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const GET_USERS_SUCCES = 'GET_USERS_SUCCES';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const REMOVE_LOGIN_RESPONSE = 'REMOVE_LOGIN_RESPONSE';
// LOGIN
// UPDATE PROFILE SECTION
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
// UPDATE PASSWORD SECTION
// RESENT OTP SECTION
export const VERFIY_OTP_SUCCESS = 'VERFIY_OTP_SUCCESS'
export const VERFIY_OTP_FAILURE = 'VERFIY_OTP_FAILURE'
// RESENTOTP SECTION
// RESENT OTP SECTION
export const RESENT_OTP_SUCCESS = 'RESENT_OTP_SUCCESS'
export const RESENT_OTP_FAILURE = 'RESENT_OTP_FAILURE'
// RESENTOTP SECTION
export const CHECK_MOBILE_SUCCESS = 'CHECK_MOBILE_SUCCESS'
export const CHECK_MOBILE_ERROR = 'CHECK_MOBILE_ERROR'
// FORGOT PASSWORD SECTION
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
// FORGOT PASSWORD SECTION
// UPDATE PASSWORD SECTION
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
// UPDATE PASSWORD SECTION
// CHANGE PASSWORD SECTION
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
// CHANGE PASSWORD SECTION
// CHANGE_PASSWORD SECTION
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE'
// USER_LIST SECTION
// USER_LIST SECTION
export const ADD_SUBJECTS_SUCCESS = 'ADD_SUBJECTS_SUCCESS'
export const ADD_SUBJECTS_FAILURE = 'ADD_SUBJECTS_FAILURE'
// ADD_SUBJECTS SECTION
// ADD_SUBJECTS SECTION
export const CREATE_ALL_CATEGORY_SUCCESS = 'CREATE_ALL_CATEGORY_SUCCESS'
export const CREATE_ALL_CATEGORY_FAILURE = 'CREATE_ALL_CATEGORY_FAILURE'
// CREATE_ALL_CATEGORY SECTION
// CREATE_ALL_CATEGORY SECTION
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS'
export const GET_SUBJECTS_FAILURE = 'GET_SUBJECTS_FAILURE'
export const GET_SUBJECTS_TOPICS_SUCCESS = 'GET_SUBJECTS_TOPICS_SUCCESS'
export const GET_SUBJECTS_TOPICS_FAILURE = 'GET_SUBJECTS_TOPICS_FAILURE'
// GET_SUBJECTS SECTION
// GET_SUBJECTS SECTION
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS'
export const ADD_PLAN_FAILURE = 'ADD_PLAN_FAILURE'
// ADD_PLAN SECTION
// ADD_PLAN SECTION
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS'
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE'
// GET_PLAN SECTION
// GET_PLAN SECTION
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS'
export const ADD_VIDEO_FAILURE = 'ADD_VIDEO_FAILURE'
// ADD_VIDEO SECTION
// ADD_VIDEO SECTION
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS'
export const GET_VIDEO_FAILURE = 'GET_VIDEO_FAILURE'

// Blog
export const GET_BLOG = 'GET_BLOG'
export const GET_BLOG_FAILURE = 'GET_BLOG_FAILURE'
export const GET_WHATSNEW = 'GET_WHATSNEW'
export const GET_WHATSNEW_FAILURE = 'GET_WHATSNEW_FAILURE'
export const GET_EBOOK = 'GET_EBOOK'
export const GET_EBOOK_FAILURE = 'GET_EBOOK_FAILURE'
export const GET_DAILYVOCAB = 'GET_DAILYVOCAB'
export const GET_DAILYVOCAB_FAILURE = 'GET_DAILYVOCAB_FAILURE'
export const GET_JOBS = 'GET_JOBS'
export const GET_JOBS_FAILURE = 'GET_JOBS_FAILURE'
export const GET_SUBJECTS_LIST = 'GET_SUBJECTS_LIST'
export const GET_SUBJECTS_LIST_FAILURE = 'GET_SUBJECTS_LIST_FAILURE'

